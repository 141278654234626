.btn {
  padding: 7px 25px;
  border-radius: 5px;
  background: #f76e11;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 30px;
}

.btn:hover {
  color: #fff;
}
.heroSlider_wrap{
  /* border: 1px solid white; */
  height: 400px;
}
@media only screen and (max-width: 992px) {
  .slider__content h2 {
    font-size: 1.5rem;
  }

  .slider__content p {
    font-size: 0.8rem;
  }

  .slider__content .btn {
    padding: 5px 20px;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .slider__content {
    padding-left: 0;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .heroSlider_wrap{
    /* border: 1px solid white; */
    height: auto;
  }
}
