/* =========== google fonts =========== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

/* ======== base style ======= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background: #0a071a;
}

section,
footer {
  padding: 70px 0px;
}

h1,
h2 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #f5b70a;
}

p {
  font-size: 1rem;
  color: #c4c4c4;
  line-height: 30px;
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

i {
  color: #fff;
}
.button_p {
  outline: none;
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: #f5b70a;
  color: white;
}

@media only screen and (max-width: 767px) {
  section {
    padding: 40px 0px;
  }
  h1,
  h2 {
    font-size: 1.4rem;
  }
}
.link_hover:hover {
  color: #f5b70a;
}
.loader_home {
  background-color: rgba(63, 62, 62, 0.453);
  height: 100vh;
  width: 100%;
  z-index: 9999999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999990;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 40px;
}
.cp {
  cursor: pointer;
}
/* /////////////////////////////
///////////// Menu //////////////
/////////////////////////////////
*/
.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}

.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background: #0a071a;
  color: white;
}
.search__widget input {
  border: none;
  color: white;
  background: none;
}

.search__widget input:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: 20px;
  }
  .search__widget input {
    width: 100%;
    font-size: 0.8rem;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }
}
/* /////////////////////////////
///////////// About //////////////
/////////////////////////////////
*/
.about_restaurant_img {
  height: 400px;
  /* width: 100%;
  object-fit: contain; */
}
.about_restaurant_img:hover {
  box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
  -webkit-box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
  -moz-box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
}
@media only screen and (max-width: 768px) {
  .about_restaurant_img {
    height: 300px;
  }
}
/* /////////////////////////////
///////////// contact us //////////////
/////////////////////////////////
*/
.contact_info {
  font-size: 30px;
  margin-left: 40px;
}
.map_embed {
  height: 400px;
  width: 100%;
}
.contact_form_input {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  outline: none;
  background: #0a071a;
  color: white;
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .contact_info {
    font-size: 1rem;
    margin-left: 0px;
  }
  .map_embed {
    height: 300px;
  }
}
/* /////////////////////////////
///////////// cart //////////////
/////////////////////////////////
*/
.empty_section {
  width: 100%;
  border: 1px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.empty_section h3 {
  color: #c4c4c4;
}
.cp_p {
  display: flex;
  margin-bottom: 10px;
  padding: 5px;
  padding-bottom: 20px;
  border-bottom: 1px dashed white;
  color: white;
  position: relative;
}
.cp_p .p_img {
  height: 140px;
  max-width: 140px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.cp_p .detail_cp {
  display: flex;
  justify-content: center;
  align-items: left;
  padding-left: 20px;
  flex-direction: column;
}
.cp_p .detail_cp .quant_btn {
  margin-top: 10px;
  padding: 4px 0px;
  border-radius: 8px;
  border: 1px solid white;
  width: fit-content;
}
.cp_p .detail_cp .quant_btn button {
  width: 25px;
  background: none;
  border: none;
  outline: none;
  color: white;
  margin: 0 8px;
}
.delte_bt_p {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%, 0);
  cursor: pointer;
}
.p_detail_c {
  color: white;
  background-color: #0a071a;
  padding: 10px;
}
.p_detail_c td {
  padding: 10px 0;
}
.p_detail_c button {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5b70a;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.cart_top {
  margin: 20px 0px;
  padding-top: 50px;
}
.clickable_label {
  background-color: #212245;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  height: 60px;
  padding: 0px 20px;
  cursor: pointer;
}
.clickable_label h3 {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
}
.conntinue_btn {
  float: right;
  padding: 7px;
  border-radius: 5px;
  background-color: #f5b70a;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.address_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-size: 15px;
}
.address_options label {
  color: white;
  margin-left: 20px;
}
.address_input {
  width: 100%;
  outline: none;
  background: #0a071a;
  color: white;
  padding: 7px;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .cart_top {
    margin: 20px 0px;
    padding-top: 20px;
  }
}
.address_removeBtn {
  padding: 4px;
  border-radius: 5px;
  font-size: 10px;
  background-color: red;
  color: white;
}
