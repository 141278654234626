@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap);
/* =========== google fonts =========== */

/* ======== base style ======= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background: #0a071a;
}

section,
footer {
  padding: 70px 0px;
}

h1,
h2 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #f5b70a;
}

p {
  font-size: 1rem;
  color: #c4c4c4;
  line-height: 30px;
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

i {
  color: #fff;
}
.button_p {
  outline: none;
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: #f5b70a;
  color: white;
}

@media only screen and (max-width: 767px) {
  section {
    padding: 40px 0px;
  }
  h1,
  h2 {
    font-size: 1.4rem;
  }
}
.link_hover:hover {
  color: #f5b70a;
}
.loader_home {
  background-color: rgba(63, 62, 62, 0.453);
  height: 100vh;
  width: 100%;
  z-index: 9999999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999990;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 40px;
}
.cp {
  cursor: pointer;
}
/* /////////////////////////////
///////////// Menu //////////////
/////////////////////////////////
*/
.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}

.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background: #0a071a;
  color: white;
}
.search__widget input {
  border: none;
  color: white;
  background: none;
}

.search__widget input:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: 20px;
  }
  .search__widget input {
    width: 100%;
    font-size: 0.8rem;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }
}
/* /////////////////////////////
///////////// About //////////////
/////////////////////////////////
*/
.about_restaurant_img {
  height: 400px;
  /* width: 100%;
  object-fit: contain; */
}
.about_restaurant_img:hover {
  box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
  -webkit-box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
  -moz-box-shadow: -1px -1px 23px 4px rgba(245, 183, 10, 0.75);
}
@media only screen and (max-width: 768px) {
  .about_restaurant_img {
    height: 300px;
  }
}
/* /////////////////////////////
///////////// contact us //////////////
/////////////////////////////////
*/
.contact_info {
  font-size: 30px;
  margin-left: 40px;
}
.map_embed {
  height: 400px;
  width: 100%;
}
.contact_form_input {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  outline: none;
  background: #0a071a;
  color: white;
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .contact_info {
    font-size: 1rem;
    margin-left: 0px;
  }
  .map_embed {
    height: 300px;
  }
}
/* /////////////////////////////
///////////// cart //////////////
/////////////////////////////////
*/
.empty_section {
  width: 100%;
  border: 1px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.empty_section h3 {
  color: #c4c4c4;
}
.cp_p {
  display: flex;
  margin-bottom: 10px;
  padding: 5px;
  padding-bottom: 20px;
  border-bottom: 1px dashed white;
  color: white;
  position: relative;
}
.cp_p .p_img {
  height: 140px;
  max-width: 140px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.cp_p .detail_cp {
  display: flex;
  justify-content: center;
  align-items: left;
  padding-left: 20px;
  flex-direction: column;
}
.cp_p .detail_cp .quant_btn {
  margin-top: 10px;
  padding: 4px 0px;
  border-radius: 8px;
  border: 1px solid white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.cp_p .detail_cp .quant_btn button {
  width: 25px;
  background: none;
  border: none;
  outline: none;
  color: white;
  margin: 0 8px;
}
.delte_bt_p {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%, 0);
          transform: translateY(-50%, 0);
  cursor: pointer;
}
.p_detail_c {
  color: white;
  background-color: #0a071a;
  padding: 10px;
}
.p_detail_c td {
  padding: 10px 0;
}
.p_detail_c button {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5b70a;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.cart_top {
  margin: 20px 0px;
  padding-top: 50px;
}
.clickable_label {
  background-color: #212245;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  height: 60px;
  padding: 0px 20px;
  cursor: pointer;
}
.clickable_label h3 {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
}
.conntinue_btn {
  float: right;
  padding: 7px;
  border-radius: 5px;
  background-color: #f5b70a;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.address_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-size: 15px;
}
.address_options label {
  color: white;
  margin-left: 20px;
}
.address_input {
  width: 100%;
  outline: none;
  background: #0a071a;
  color: white;
  padding: 7px;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .cart_top {
    margin: 20px 0px;
    padding-top: 20px;
  }
}
.address_removeBtn {
  padding: 4px;
  border-radius: 5px;
  font-size: 10px;
  background-color: red;
  color: white;
}

.btn {
  padding: 7px 25px;
  border-radius: 5px;
  background: #f76e11;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 30px;
}

.btn:hover {
  color: #fff;
}
.heroSlider_wrap{
  /* border: 1px solid white; */
  height: 400px;
}
@media only screen and (max-width: 992px) {
  .slider__content h2 {
    font-size: 1.5rem;
  }

  .slider__content p {
    font-size: 0.8rem;
  }

  .slider__content .btn {
    padding: 5px 20px;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .slider__content {
    padding-left: 0;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .heroSlider_wrap{
    /* border: 1px solid white; */
    height: auto;
  }
}

.single__product {
  background: #141124;
  border-radius: 10px;
  cursor: pointer;
  -webkit-animation: animate 1s ease-in;
          animation: animate 1s ease-in;
}

.single__product:hover {
  box-shadow: 5px 5px 10px -5px #f5b70a;
}

.product__content {
  padding: 0px 20px;
  padding-bottom: 15px;
}

.product__content h6 {
  color: #f5b70a;
  text-align: center;
}
.rating span i {
  color: #f76e11;
}

.rating {
  margin-bottom: 10px;
}

.price {
  color: #fff;
}

.price span {
  color: #f5b70a;
  font-size: 1.3rem;
  font-weight: 500;
}

.shopping__icon {
  width: 35px;
  color: white;
  height: 30px;
  border-radius: 5px;
  background: #f76e11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product__img{
  height: 210px;
  overflow: hidden;
}
.product__img img{
  max-width:100%;
max-height:100%;
object-fit: contain;
}
@-webkit-keyframes animate {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes animate {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media only screen and (max-width: 767px) {
  .product__content h6 {
    font-size: 0.9rem;
  }

  .price {
    font-size: 0.8rem;
  }

  .price span {
    font-size: 0.9rem;
  }
  .product__img{
    height: 120px;
  }
}

@media only screen and (max-width: 992px) {
  .popular__menu-title {
    font-size: 1.4rem;
  }
}

.single__feature span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ff766b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.single__feature span i {
  font-size: 1.5rem;
}

.choose__content h4 {
  color: #fff;
  margin-bottom: 15px;
}

.single__feature h6 {
  color: #f5b70a;
  margin-bottom: 0;
}

.single__feature p {
  font-size: 0.7rem;
}

.feature__icon-two {
  background: #2ace92 !important;
}

.feature__icon-3 {
  background: #f5b70a !important;
}

.feature__icon-4 {
  background: #7865ff !important;
}

@media only screen and (max-width: 992px) {
  .choose__content h2 {
    font-size: 1.5rem;
  }

  .choose__content h4 {
    font-size: 1rem;
  }

  .choose__content p {
    font-size: 0.8rem;
  }

  .single__feature h6 {
    font-size: 0.8rem;
  }

  .single__feature p {
    font-size: 0.6rem;
  }
}

.filter-btn {
  border: 1px solid #f76e11;
  padding: 7px 25px;
  background: transparent;
  margin-left: 15px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.menu__title {
  color: #f5b70a;
}

.active__btn {
  background: #f76e11;
}

@media only screen and (max-width: 992px) {
  .menu__title {
    font-size: 1.4rem;
  }
  .filter-btn {
    padding: 5px 20px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .filter-btn {
    margin-bottom: 15px;
  }
}

.single__testimonial h6 {
  color: #f5b70a;
  font-size: 1.1rem;
}

.single__testimonial p {
  font-size: 0.8rem;
  color: #fff;
}

.single__testimonial {
  padding: 20px;
}

@media only screen and (max-width: 992px) {
  .slider__content h2 {
    font-size: 1.5rem;
    margin-bottom: 15px !important;
  }

  .single__testimonial p {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .slider__wrapper {
    flex-direction: column;
  }

  .slider__content {
    width: 100% !important;
  }

  .slider__img {
    display: none;
  }
}

.app__container {
  background: #f5b70a;
  border-radius: 10px;
}

.app__img {
  margin-bottom: -100px;
}

.app__content h5 {
  color: #0a071a;
}

.app__content h2 {
  color: #0a071a;
  font-weight: 600;
}

.app__content {
  padding-top: 50px;
}

.app__content p {
  color: #fff;
}

.btn__apple {
  background: #0a071a;
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}

.btn__google {
  background: #fff;
  color: #0a071a;
  border: none;
  padding: 7px 25px;
  border-radius: 5px;
}

.btn__google i {
  color: #0a071a;
}

.btn__apple:hover a {
  color: #fff;
}

.btn__google:hover a {
  color: #0a071a;
}

@media only screen and (max-width: 992px) {
  .app__img img {
    width: 100%;
  }

  .app__content h5 {
    font-size: 1rem;
  }

  .app__content h2 {
    font-size: 1.5rem;
  }

  .app__content p {
    font-size: 0.7rem;
    line-height: 25px;
  }

  .btn__apple,
  .btn__google {
    padding: 5px 20px;
    font-size: 0.8rem;
  }

  .app__content {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .app__content {
    padding: 30px;
  }

  .app__img {
    margin-bottom: 0;
  }
}

.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: #141124;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo h2 {
  margin-bottom: 0;
}

.nav__list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  grid-column-gap: 2.7rem;
  -webkit-column-gap: 2.7rem;
          column-gap: 2.7rem;
}

.nav__item a {
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #f5b70a;
}

.custom__search {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  height: 35px;
  border-radius: 50px;
  background: #0a071a;
}

.custom__search input {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
}

.custom__search span i {
  cursor: pointer;
}

.mobile__menu span i {
  font-size: 1.3rem;
}

.mobile__menu {
  display: none;
}

.cart__icon {
  position: relative;
  z-index: 11;
  width: 40px;
  height: 30px;
  border-radius: 5px;
  background: #f76e11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  position: absolute;
  top: -2px;
  right: -3px;
  z-index: 12;
  color: #141124;
  font-size: 0.8rem;
  font-weight: 700;
}
.logo_img {
  height: 44px;
}

/* ==========  make it responsive ========= */

@media only screen and (max-width: 992px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #0a071a7e;
    display: none;
  }

  .nav__list__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    z-index: 100;
    background: #141124;
    flex-direction: column;
    box-shadow: 5px 5px 15px -5px #f5b70a;
    justify-content: center !important;
    padding: 0px 20px;
  }

  .nav__list {
    flex-direction: column;
    padding: 0;
  }

  .nav__item a {
    font-size: 0.9rem;
  }

  .header {
    line-height: 40px;
    height: 60px;
    padding: 10px 0px;
  }

  .custom__search {
    border: 1px solid #c4c4c425;
    padding: 5px 15px;
    font-size: 0.9rem;
    height: 30px;
    margin-top: 15px;
  }

  .mobile__menu {
    display: block;
  }

  .logo h2 {
    font-size: 1.5rem;
  }

  .active__menu {
    display: block;
  }
  .logo_img {
    height: 30px;
  }
}

.footer {
  padding-bottom: 0;
}
.footer__link-title {
  color: #fff;
}

.link__item {
  padding-left: 0;
  background: transparent;
  color: #c4c4c4;
}

.link__item a {
  color: #c4c4c4;
}

.footer__bottom {
  background: #141124;
  padding: 20px 0px;
  text-align: center;
  margin-top: 50px;
}

.footer__bottom p {
  font-size: 0.9rem;
  margin-bottom: 0;
}
.logo_img_footer {
  height: 50px;
}
@media only screen and (max-width: 992px) {
  .link__item a {
    font-size: 0.8rem;
  }

  .link__item {
    font-size: 0.8rem;
  }

  .logo p {
    font-size: 0.8rem;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }

  .footer__bottom {
    padding: 15px 0px;
  }
  .logo_img_footer {
    height: 40px;
  }
}
